<script setup lang="ts">
import { Button, Space, type TableColumnType, Tag, Tooltip } from 'ant-design-vue'
import { formatDate } from '@vueuse/core'
import type { RendererElement, RendererNode } from 'vue'
import { getDeviceModelsApi } from '@/api/device-model'
import { queryPlatformHistoryEventApi } from '@/api/event'
import { getSubSystemsApi } from '@/api/sub-system'
import { ConfirmTypeMap, EventTypeColorMap, EventTypeMap } from '@/constant/event'

const videoPath = ref('')
const historyDeviceName = ref('')
const historyVisible = ref(false)
function historyClose() {
  historyVisible.value = false
}

const columns = [
  { title: '事件名称', dataIndex: 'eventName', width: 200, ellipsis: true, fixed: 'left' },
  { title: '事件类型', dataIndex: 'eventType', width: 120, customRender: ({ record }) => {
    return h(Tag, { color: EventTypeColorMap.get(record.eventType) ?? 'default' }, () => EventTypeMap.get(record.eventType) ?? '未知')
  } },
  { title: '设备名称', dataIndex: 'deviceName', width: 200, ellipsis: true },
  { title: '设备编号', dataIndex: 'deviceCode', width: 200, ellipsis: true },
  { title: '设备模型', dataIndex: 'deviceModelName', width: 200, ellipsis: true },
  { title: '子系统', dataIndex: 'subSysName', width: 120, ellipsis: true },
  { title: '触发时间', dataIndex: 'triggeredAt', width: 165, customRender: ({ record }) => (formatDate(new Date(record.triggeredAt), 'YYYY-MM-DD HH:mm:ss')) },
  { title: '消失时间', dataIndex: 'triggeredAt', width: 165, customRender: ({ record }) => (formatDate(new Date(record.disappearedAt), 'YYYY-MM-DD HH:mm:ss')) },
  { title: '事件处理', dataIndex: 'confirmType', width: 120, customRender: ({ record }) => {
    if (record.confirmType == null)
      return '未处理'
    return h(Tooltip, { placement: 'top', title: record.confirmRemark ?? '无说明' }, () => ConfirmTypeMap.get(record.confirmType))
  } },
  { title: '处理人', dataIndex: 'confirmUser', width: 120, ellipsis: true, customRender: ({ record }) => (record.confirmUser ?? '') },
  { title: '处理时间', dataIndex: 'updatedAt', width: 165, customRender: ({ record }) => {
    if (record.confirmType != null && record.confirmedAt != null)
      return formatDate(new Date(record.confirmedAt), 'YYYY-MM-DD HH:mm:ss')
    return ''
  } },
  { title: '描述', dataIndex: 'description', width: 300, ellipsis: true },
  { title: '操作', fixed: 'right', width: 120, customRender: ({ record }) => {
    const renderArr: globalThis.VNode<RendererNode, RendererElement, { [key: string]: any }>[] = []
    if (record.videoPath != null)
      renderArr.push(h(Button, { type: 'link', size: 'small', onClick: () => showHistoryVideo(record) }, () => '查看录像'))

    return h(Space, { direction: 'horizontal' }, () => renderArr)
  } },
] as TableColumnType[]

const { state, onPageChange, onSearch } = usePageFetch({ apiFun: queryPlatformHistoryEventApi, columns, needPaging: true })

function showHistoryVideo(event: HistoryPlatformEvent) {
  videoPath.value = event.videoPath
  historyDeviceName.value = event.deviceName
  historyVisible.value = true
}

const search = ref<TableSearchType[]>([
  { label: '事件类型', name: 'eventTypes', placeholder: '请选择事件类型', required: false, type: 'select', options: [
    { label: '信息', value: 'INFO' },
    { label: '告警', value: 'WARN' },
    { label: '故障', value: 'FAULT' },
  ] },
  { label: '设备名称', name: 'deviceName', placeholder: '请输入设备名称', required: false, type: 'input' },
  { label: '设备编号', name: 'deviceCode', placeholder: '请输入设备编号', required: false, type: 'input' },
])

onMounted(initOptions)

async function initOptions() {
  const deviceModels = await getDeviceModelsApi()
  const options = deviceModels.map(({ deviceModelName, deviceModelCode }) => ({ label: deviceModelName, value: deviceModelCode }))
  search.value.splice(3, 1, { label: '设备模型', name: 'deviceModelCode', placeholder: '请选择设备模型', required: false, type: 'select', options })

  const subSys = await getSubSystemsApi()
  const subSysOptions = subSys.map(({ id, subSysName }) => ({ label: subSysName, value: id }))
  search.value.splice(4, 1, { label: '子系统', name: 'subSysId', placeholder: '请选择子系统', required: false, type: 'select', options: subSysOptions })

  search.value.push({ label: '开始时间', name: 'triggeredFrom', placeholder: '请选择开始时间', required: false, type: 'date' })
  search.value.push({ label: '结束时间', name: 'triggeredTo', placeholder: '请选择结束时间', required: false, type: 'date' })
}
</script>

<template>
  <page-container>
    <content-header title="历史事件" />
    <flex-content>
      <div h-full overflow-hidden flex flex-col>
        <div rounded p-12px mb-8px style="background: var(--bg-color)">
          <search-form :search="search" @search="onSearch" />
        </div>
        <table-layout :need-header="false">
          <template #content>
            <base-table v-bind="state" :x="1300" @page-change="onPageChange" />
          </template>
        </table-layout>
      </div>
    </flex-content>

    <HistoryVideo :video-path="videoPath" video-player-status="EASY_PLAYER" :device-name="historyDeviceName" :visible="historyVisible" @on-close="historyClose" />
  </page-container>
</template>
